var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("c-dialog", {
    attrs: { title: _vm.title, width: 750, showDialog: _vm.open },
    on: {
      "update:showDialog": function ($event) {
        _vm.open = $event
      },
      "update:show-dialog": function ($event) {
        _vm.open = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "el-form",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.detailLoading,
                    expression: "detailLoading",
                  },
                ],
                ref: "form",
                attrs: {
                  model: _vm.vipForm,
                  rules: _vm.rules,
                  "label-width": "80px",
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员卡号", prop: "vipNo" } },
                          [
                            _c("el-input", {
                              staticClass: "pickerTime",
                              attrs: {
                                placeholder: "请输入会员卡号",
                                maxlength: "20",
                                disabled: _vm.vipForm.vipId,
                              },
                              model: {
                                value: _vm.vipForm.vipNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "vipNo", $$v)
                                },
                                expression: "vipForm.vipNo",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员名称", prop: "vipName" } },
                          [
                            _c("el-input", {
                              staticClass: "pickerTime",
                              attrs: {
                                placeholder: "请输入会员名称",
                                maxlength: "11",
                              },
                              model: {
                                value: _vm.vipForm.vipName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "vipName", $$v)
                                },
                                expression: "vipForm.vipName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "手机号", prop: "tel" } },
                          [
                            _c("el-input", {
                              staticClass: "pickerTime",
                              attrs: {
                                placeholder: "请输入手机号",
                                maxlength: "11",
                              },
                              model: {
                                value: _vm.vipForm.tel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "tel", _vm._n($$v))
                                },
                                expression: "vipForm.tel",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "性别", prop: "sex" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择性别",
                                  filterable: "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.vipForm.sex,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vipForm, "sex", $$v)
                                  },
                                  expression: "vipForm.sex",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.sys_user_sex,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: parseInt(dict.value),
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员级别", prop: "vipLevelId" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择会员级别",
                                  clearable: "",
                                  disabled: _vm.vipForm.vipId != undefined,
                                },
                                on: { change: _vm.changeVipLevel },
                                model: {
                                  value: _vm.vipForm.vipLevelId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vipForm, "vipLevelId", $$v)
                                  },
                                  expression: "vipForm.vipLevelId",
                                },
                              },
                              _vm._l(_vm.vipCardTreeOptions, function (item) {
                                return _c("el-option", {
                                  key: item.vipLevelId,
                                  attrs: {
                                    label: item.vipLevelName,
                                    value: item.vipLevelId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员生日", prop: "sortNo" } },
                          [
                            _c("j-date-picker", {
                              staticClass: "pickerTime",
                              attrs: {
                                id: "value1",
                                width: _vm.width1,
                                placeholder: _vm.placeholder,
                                "picker-options": _vm.pickerOptions,
                                rangeSeparator: _vm.rangeSeparator,
                                disabled: _vm.disabled,
                                showLunarClass: _vm.showLunarClass,
                                showLunarControl: _vm.showLunarControl,
                                type: _vm.type,
                                showBackYears: _vm.showBackYears,
                                showLunarIcon: _vm.showLunarIcon,
                                format: _vm.format,
                                isLunarProps: false,
                                "disabled-dates": _vm.disableDates,
                              },
                              on: { lunarCalendar: _vm.calendarFunction },
                              model: {
                                value: _vm.vipForm.birthday,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "birthday", $$v)
                                },
                                expression: "vipForm.birthday",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "begValidDate",
                            attrs: { label: "生效日期", prop: "begValidDate" },
                          },
                          [
                            _c("el-date-picker", {
                              staticClass: "pickerTime",
                              attrs: {
                                clearable: "",
                                type: "date",
                                placeholder: "请选择生效日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.vipForm.begValidDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "begValidDate", $$v)
                                },
                                expression: "vipForm.begValidDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "endValidDate",
                            attrs: { label: "结束日期", prop: "endValidDate" },
                          },
                          [
                            _c("el-date-picker", {
                              staticClass: "pickerTime",
                              attrs: {
                                clearable: "",
                                type: "date",
                                placeholder: "请选择结束日期",
                                "value-format": "yyyy-MM-dd",
                                format: "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.vipForm.endValidDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "endValidDate", $$v)
                                },
                                expression: "vipForm.endValidDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "客户地址", prop: "regionId" } },
                          [
                            _c("el-cascader", {
                              attrs: {
                                filterable: "",
                                remote: "",
                                "reserve-keyword": "",
                                options: _vm.provinceList,
                                props: _vm.defaultProp,
                                placeholder: "请选择地址",
                                clearable: "",
                              },
                              model: {
                                value: _vm.vipForm.regionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "regionId", $$v)
                                },
                                expression: "vipForm.regionId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "会员状态", prop: "cardStatus" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "会员状态",
                                  filterable: "",
                                  clearable: "",
                                  disabled: true,
                                },
                                model: {
                                  value: _vm.vipForm.cardStatus,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.vipForm, "cardStatus", $$v)
                                  },
                                  expression: "vipForm.cardStatus",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.vip_card_status,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.vipForm.vipId &&
                _vm.vipForm.shop_VIP_CONFIG_PASSWORD_12 == "Y"
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "会员密码" } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "x-f" },
                                  [
                                    _c("el-input", {
                                      staticClass: "pickerTime",
                                      attrs: {
                                        placeholder: "请输入会员密码(6~18字符)",
                                        maxlength: "18",
                                        disabled: "",
                                      },
                                      model: {
                                        value: _vm.vipForm.usePassWord,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.vipForm,
                                            "usePassWord",
                                            $$v
                                          )
                                        },
                                        expression: "vipForm.usePassWord",
                                      },
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "marL10",
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                        },
                                        on: { click: _vm.emptyPassWord },
                                      },
                                      [_vm._v("清空")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "信用额度",
                              prop: "usableCreditMoney",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "pickerTime",
                              attrs: { placeholder: "请输入信用额度" },
                              on: {
                                input: function ($event) {
                                  return _vm.limitInputlength(
                                    _vm.vipForm.usableCreditMoney,
                                    _vm.vipForm,
                                    "usableCreditMoney",
                                    true,
                                    8,
                                    true
                                  )
                                },
                              },
                              model: {
                                value: _vm.vipForm.usableCreditMoney,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.vipForm,
                                    "usableCreditMoney",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "vipForm.usableCreditMoney",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "主卡卡号", prop: "mainVipNo" } },
                          [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "请输入主卡卡号",
                                    maxlength: "20",
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.check()
                                    },
                                  },
                                  model: {
                                    value: _vm.vipForm.mainVipNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.vipForm,
                                        "mainVipNo",
                                        _vm._n($$v)
                                      )
                                    },
                                    expression: "vipForm.mainVipNo",
                                  },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "marL5",
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.check()
                                      },
                                    },
                                  },
                                  [_vm._v("校验")]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "详细地址", prop: "regionAddress" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "remark",
                              attrs: { placeholder: "请输入详细地址" },
                              model: {
                                value: _vm.vipForm.regionAddress,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "regionAddress", $$v)
                                },
                                expression: "vipForm.regionAddress",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 20 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "text",
                                placeholder: "备注长度介于 1 和 60 字符之间",
                                maxlength: "60",
                              },
                              model: {
                                value: _vm.vipForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.vipForm, "remark", $$v)
                                },
                                expression: "vipForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "buttonList",
        fn: function () {
          return [
            _c(
              "el-button",
              { staticClass: "marL10", on: { click: _vm.cancel } },
              [_vm._v("取消")]
            ),
            _c(
              "el-button",
              {
                staticClass: "marL10",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm(false)
                  },
                },
              },
              [_vm._v("保存并新增")]
            ),
            _c(
              "el-button",
              {
                staticClass: "marL10",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm(true)
                  },
                },
              },
              [_vm._v("保存")]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }